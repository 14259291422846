<template>
  <b-card v-if="this.projectData != null" no-body>
    <b-card-header>
      <h4 class="mb-0">{{ $t("message.dashboardAdm.projects") }}</h4>
      <b-card-text class="font-small-2 mr-25 mb-0">
        {{ localeDate(projectData.timestamp) || "Dados não carregados" }}
      </b-card-text>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="chartPercentage()"
    />
    <b-row class="text-center mx-0">
      <b-col
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t("message.dashboardAdm.pending") }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ projectData.pending || 0 }}
        </h3>
      </b-col>

      <b-col
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t("message.dashboardAdm.progress") }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ projectData.inprogress || 0 }}
        </h3>
      </b-col>

      <b-col
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t("message.dashboardAdm.late") }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ projectData.late || 0 }}
        </h3>
      </b-col>

      <b-col
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t("message.dashboardAdm.finished") }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ projectData.finished || 0 }}
        </h3>
      </b-col>

      <b-col class="border-top d-flex align-items-between flex-column py-1">
        <b-card-text class="text-muted mb-0">
          {{ $t("message.dashboardAdm.closed") }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ projectData.closed || 0 }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import store from "@/store";
import moment from "moment";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5e5873";
const $goalStrokeColor2 = "#51e5a8";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },

  created() {
    if (this.userData.email != "root@braxxy.com.br") {
      axios
        .get(`${URL_API}dashboard/project/${this.userData.id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.projectData = response.data;
        });
    }
  },

  methods: {
    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    chartPercentage() {
      if (this.projectData.length != 0) {
        var series = [];
        if (this.projectData.percentage != undefined) {
          series = [this.projectData.percentage.toFixed(0)];
        }

        if (series > 0) {
          return series;
        } else {
          return [0];
        }
      }

      return [0];
    },
  },

  data() {
    return {
      //CurrentUser
      userData: store.state.user.userData,

      projectData: [],

      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: "2.86rem",
                fontWeight: "600",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    };
  },
};
</script>
