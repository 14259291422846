<template>
  <b-card v-if="data" class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>
        {{ $t("RecentActivities") }}
      </b-card-title>
    </b-card-header>

    <b-overlay
      variant="transparent"
      opacity="1.00"
      blur="6px"
      :show="showOverlay"
      rounded="sm"
      no-wrap
    ></b-overlay>
    <b-card-body>
      <div
        v-for="transaction in auditList"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              size="42"
              :text="avatarTextDot(transaction.user)"
              v-bind:src="'data:image/jpeg;base64,' + transaction.avatar"
              v-b-tooltip.hover
              class="pull-up"
              :title="transaction.user"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ titleName(transaction.type) }}
            </h6>
            <small>{{ transaction.description }}</small>
          </b-media-body>
        </b-media>
        <b-badge
          pill
          :variant="`light-${titleActionColor(transaction.action)}`"
          class="text-capitalize"
        >
          <span class="align-text-top text-capitalize">{{
            titleAction(transaction.action)
          }}</span>
        </b-badge>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import { avatarTextDot } from "@core/utils/filter";
import store from "@/store";
import AccessControl from "@core/utils/access-control";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  async created() {
    this.showOverlay = true;
    await this.getAuditList(); 
  },

  data() {
    return {
      auditList: [],
      showOverlay: false,
      //CurrentUser
      userData: store.state.user.userData,
    };
  },

  methods: {
    async getAuditList() {
      if (this.restrictions("audit-list-all")) {
        await axios
          .get(`${URL_API}audit/top5`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            this.auditList = response.data;
          });
      } else {
        await axios
          .get(`${URL_API}audit/${this.userData.email}/top5`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            this.auditList = response.data;
          });
      }
      setTimeout(() => {
      this.showOverlay = false;
    }, 1000)
    },

    restrictions(value) {
      return AccessControl(value);
    },

    titleName(title) {
      if (title === "Timesheet") {
        return this.getColLabel("timeSheet");
      }
      if (title === "Task") {
        return this.getColLabel("task");
      }
      if (title === "Project") {
        return this.getColLabel("project");
      }
      if (title === "Auth") {
        return this.getColLabel("auth");
      }
      return this.getColLabel("none");
    },

    titleAction(action) {
      if (action === "Created") {
        return this.getColLabel("created");
      }
      if (action === "Changed") {
        return this.getColLabel("changed");
      }
      if (action === "Deleted") {
        return this.getColLabel("deleted");
      }
      return this.getColLabel("none");
    },

    titleActionColor(action) {
      if (action === "Created") {
        return "success";
      }
      if (action === "Changed") {
        return "warning";
      }
      if (action === "Deleted") {
        return "danger";
      }
      return this.getColLabel("none");
    },

    getColLabel(colName) {
      return this.$i18n.t("message.activity." + colName);
    },
  },

  setup() {
    return {
      // Avatar
      avatarTextDot,
    };
  },
};
</script>
