<template>
  <b-card v-if="data" class="card-congratulation-medal">
    <h5>{{ $("message.commands.welcome") }} 👋 {{ data.name }}!</h5>
    <b-card-text class="font-small-3">
      {{ $("message.bookmark.youHaveNewNotification") }}
    </b-card-text>
    <h3 class="mb-75 mt-2 pt-50">&nbsp;</h3>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="showNotifications"
    >
      {{ $("message.employees.toView") }}
    </b-button>
    <b-img
      :src="require('@/assets/images/illustration/notification.svg')"
      class="notification-icon"
      alt="Notification Pic"
    />
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink, BButton, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,

    showNotifications() {
      this.$root.$refs.NotificationDropdown.changeStateDropDown();
    },
  },
};
</script>
