<template>
  <b-card v-if="statisticList" no-body class="card-statistics">
    <b-card-header>
      <b-card-title> {{ $t("message.dashboardAdm.statistic") }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        {{ localeDate(statisticList.timestamp) || "Dados não carregados" }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6" class="mb-3 m30">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon
                  size="24"
                  icon="FileTextIcon"
                  id="projects-text"
                />
                <b-tooltip target="projects-text" triggers="hover" no-fade>
                  {{ $t("openProjectsDashboard") }}
                </b-tooltip>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ statisticList.project || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("message.dashboardAdm.projects") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col xl="3" sm="6" class="mb-2 m30">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info">
                <feather-icon size="24" icon="CheckSquareIcon" id="task-text" />
                <b-tooltip target="task-text" triggers="hover" no-fade>
                  {{ $t("openTasksDashboard") }}
                </b-tooltip>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ statisticList.task || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("message.projects.tasks") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col xl="3" sm="6" class="mb-2 m30">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-danger">
                <feather-icon
                  size="24"
                  icon="ClipboardIcon"
                  id="timeSheet-text"
                />
                <b-tooltip target="timeSheet-text" triggers="hover" no-fade>
                  {{ $t("timeSheetDashboard") }}
                </b-tooltip>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ statisticList.timesheet || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("message.dashboardAdm.timeSheet") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          xl="1"
          sm="6"
          class="mb-2 m30"
          v-if="userData.userGroup != 'accessControlManager'"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon
                  size="24"
                  icon="TrendingUpIcon"
                  id="productivity-text"
                />
                <b-tooltip target="productivity-text" triggers="hover" no-fade>
                  {{ $t("productivityDashboard") }}
                </b-tooltip>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ statisticList.productivity || 0 }}%
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("message.dashboardAdm.productivity") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col xl="1" sm="6" class="mb-2 m30" v-else>
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon
                  size="24"
                  icon="TrendingUpIcon"
                  id="productivity-text"
                />
                <b-tooltip target="productivity-text" triggers="hover" no-fade>
                  {{ $t("employeeTeam") }}
                </b-tooltip>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ statisticList.employee || 0 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("employee") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTooltip,
} from "bootstrap-vue";

import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BTooltip,
  },
  data() {
    return {
      statisticList: [],

      //CurrentUser
      userData: store.state.user.userData,
    };
  },

  methods: {
    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },
  },

  created() {
    if (this.userData.email != "root@braxxy.com.br") {
      axios
        .get(`${URL_API}dashboard/statistic/${this.userData.id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.statisticList = response.data;
        });
    }
  },
};
</script>

<style>
/* .m30 {
  margin-left: 30px;
} */
</style>
