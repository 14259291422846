<template>
  <section id="dashboard-overview" v-if="userData.systemRole != 'ROLE_ADMIN' && userData.employeeId != 'organization-root'">
    <b-row class="match-height">
      <b-col xl="12" md="6">
        <overview-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="5">
        <b-row class="match-height">
          <b-col lg="12" md="8">
            <overview-project-chart :data="data.goalOverview" />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="7">
        <overview-activity :data="data.transactionData" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <calendar />
      </b-col>
    </b-row>

    <b-modal
      id="modal-welcome"
      :hide-footer="true"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Tour do BRXMIND"
    >
      <b-carousel id="carousel-caption" controls indicators>
        <b-carousel-slide
          caption-html="<span class='text-white'>First Slide Label</span>"
          class="card-img-overlay"
          text="Donut jujubes I love topping I love sweet. Jujubes I love brownie gummi bears I love donut sweet chocolate. Tart chocolate marshmallow.Tart carrot cake muffin."
          :img-src="require('@/assets/images/slider/20.jpg')"
        />
        <b-carousel-slide
          caption-html="<span class='text-white'>Second Slide Label</span>"
          class="card-img-overlay"
          text="Tart macaroon marzipan I love soufflé apple pie wafer. Chocolate bar jelly caramels jujubes chocolate cake gummies. Cupcake cake I love cake danish carrot cake."
          :img-src="require('@/assets/images/slider/21.jpg')"
        />
      </b-carousel>
      <br />
      <b-row>
        <b-col lg="9">
          <b-form-checkbox
            v-model="showWelcomeMessage"
            @input="dontShow(showWelcomeMessage)"
          >
            <small>Não mostrar novamente</small>
          </b-form-checkbox>
        </b-col>

        <!--<b-col lg="3">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
            >
              <feather-icon icon="ArrowLeftIcon" size="12" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
            >
              <feather-icon icon="ArrowRightIcon" size="12" />
            </b-button>
          </div>
        </b-col>-->
      </b-row>

      <br />
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BCardText,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";

import { getEmployeeData } from "@/auth/utils";
import OverviewGreetings from "./OverviewGreetings.vue";
import OverviewStatistics from "./OverviewStatistics.vue";
import OverviewProjectChart from "./OverviewProjectChart.vue";
import OverviewActivity from "./OverviewActivity.vue";
import Calendar from "@/views/apps/calendar/Calendar.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    OverviewGreetings,
    OverviewStatistics,
    OverviewProjectChart,
    OverviewActivity,
    Calendar,
    BButton,
    BFormCheckbox,
    BCardText,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      data: {},

      // User Data
      userData: store.state.user.userData,

      // Param
      paramData: store.state.user.paramData,

      showWelcomeMessage: false,
      ten: 10,
    };
  },
  created() {
    if (this.userData.systemRole === "ROLE_ADMIN") {
      this.$router.push({ name: "dashboard-administrative" });
    } else if (this.userData.employeeId === "organization-root") {
      this.$router.push({ name: "dashboard-organization" });
    } else {
      this.$root.$refs.Overview = this;

      // data
      this.$http.get("/ecommerce/data").then((response) => {
        this.data = response.data;

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const employeeData = getEmployeeData();
        this.data.congratulations.name =
          employeeData.data.fullName.split(" ")[0];
      });
    }
  },

  mounted() {
    this.openModal();
  },

  methods: {
    openModal() {
      if (this.userData.tour === true && this.paramData.tour === true) {
        this.$root.$emit("bv::show::modal", "modal-welcome");
      }
    },

    reOpenModal() {
      if (this.$router.history.current.name != "dashboard-overview") {
        this.$router.push({ name: "dashboard-overview" });

        setTimeout(() => {
          this.ten -= 1;
          this.reOpenModal();
        }, 1000);
      }

      if (this.$router.history.current.name === "dashboard-overview") {
        this.$root.$emit("bv::show::modal", "modal-welcome");
      }
    },

    dontShow(value) {
      this.userData.tour = !value;

      axios({
        method: "put",
        url: `${URL_API}user/tour/${this.userData.id}`,
        headers: getHeader(this.userData),
        data: this.userData,
      }).then(() => {
        localStorage.setItem("userData", JSON.stringify(this.userData));
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}
</style>
